<template>
    <v-row justify="center">
      <v-dialog v-model="shower" scrollable max-width="800px" persistent>
        <v-card>
          <v-card-title>{{ $t('shopdee.shopdeemeekuen') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 600px;">
            <v-layout row wrap justify-center>
              <v-flex xs12 lg12 class="pa-4">
                
                <v-layout class="text-right">
                  <div v-if="statusmultipleshopdee === false" class="d-flex align-center">
                    <v-btn
                      class="white--text"
                      small
                      rounded
                      :color="color.theme"                      
                      @click="selectAll()"
                    >
                      {{ $t('med.selectfile') }}
                    </v-btn>
                  </div>
                  <div v-else>
                    ปุ่มปิดเมนู
                    <v-btn
                      outlined
                      :color="color.theme"
                      fab
                      small
                      class="ma-1 pa-0"
                      @click="clearMultipleSelect()"
                      @mouseover="showClose = true"
                      @mouseleave="showClose = false"
                    >
                      <v-icon dark>clear</v-icon>
                      <v-badge v-if="$t('default') === 'th'"
                        style="z-index:10000;bottom:-45px;left:-20px;"
                        :value="showClose"
                        :content="$t('med.close')"
                        transition="slide-y-transition"
                      ></v-badge>
                      <v-badge v-else
                        style="z-index:10000;bottom:-45px;left:-30px;"
                        :value="showClose"
                        :content="$t('med.close')"
                        transition="slide-y-transition"
                      ></v-badge>
                    </v-btn>
                  </div>
                  <v-spacer></v-spacer>
                </v-layout>
                <br>
  
                <!-- mobile -->
                <div v-if="resolutionScreen <= 500">
                  <div v-if="rootfile.length !== 0">
                    <v-list two-line class="mb-6 elevation-1 rounded-lg">
                      <template v-for="(item, i) in dtshopdee">
                        <v-list-item :key="i.document_id" style="cursor: pointer;">
                          <v-checkbox
                            v-show="statusmultipleshopdee"
                            v-model="filemultipleshopdee"
                            :value="item"
                            :disabled="item.duplicate === 'Y'"
                          />
                          <v-list-item-content>
                            <v-list-item-title v-text="item.document_id"></v-list-item-title>
                            <v-layout>
                              <v-icon color="green" small v-if="item.duplicate === 'Y'">mdi-checkbox-marked-circle-outline</v-icon>
                              <v-icon color="red" small v-else>mdi-close-circle-outline</v-icon>
                              <v-list-item-subtitle v-text="item.duplicate === 'Y' ? 'นำเข้าแล้ว' : 'ยังไม่ได้นำเข้า'"></v-list-item-subtitle>
                            </v-layout>
                          </v-list-item-content>
                          <v-list-item-action  v-if="statusmultipleshopdee === false">
                            <v-menu nudge-left="72">
                              <template v-slot:activator="{ on }" >
                                <v-btn class="elevation-0" :color="color.theme" icon fab small v-on="on">
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <!-- ปุ่มนำเข้า -->
                                <v-list-item @click="openDialogImportFileMed = true" :disabled="item.duplicate === 'Y'">
                                  <v-list-item-icon>
                                    <v-icon>mdi-application-import</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ $t('med.importfilemed') }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="fn_previewfile(item)" :disabled="item.duplicate === 'N'">
                                  <v-list-item-icon>
                                    <v-icon> mdi-eye</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ $t('shopdee.preview') }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="fn_download(item)" :disabled="item.duplicate === 'N'">
                                  <v-list-item-icon>
                                    <v-icon> mdi-arrow-down-bold-circle</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ $t('shopdee.download') }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          v-if="i < dtshopdee.length - 1"
                          :key="i.med"
                          class="mx-4"
                          style="border-color: #F5F5F5;"
                        ></v-divider>
                      </template>
                    </v-list>
                  </div>
                  <v-list v-else class="elevation-1 rounded-lg">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
  
                <!-- desktop -->
                <div v-else>                
                  <v-data-table
                    v-model="filemultipleshopdee"
                    :headers="headers"
                    :items="dtshopdee"
                    :single-select="false"
                    item-key="document_id"
                    :no-data-text="$t('tablefile.empty')"
                    :hide-default-footer="true"
                    :show-select="statusmultipleshopdee"
                    class="elevation-0"
                  >                  
                    <template v-slot:[`header.document_id`]="{ header }">
                      <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.document_type`]="{ header }">
                      <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.document_date`]="{ header }">
                      <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.duplicate`]="{ header }">
                      <span class="pointer" :style="headerTable" >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:item="props">
                      <tr style="cursor: pointer;">    
                        <td class="text-center" v-show="statusmultipleshopdee">
                          <v-checkbox
                            v-model="filemultipleshopdee"
                            :value="props.item"
                            :disabled="props.item.duplicate === 'Y'"
                          />
                        </td>
                        <td width="25%">
                          <div style="whiteSpace: nowrap; overflow: hidden; textOverflow: ellipsis; width: 150px">
                            {{ props.item.document_id }}
                          </div>
                        </td>
                        <td width="20%" class="text-center">
                          {{ props.item.document_type }}
                        </td>
                        <td width="20%" class="text-center">
                          {{ props.item.document_date }}
                        </td>
                        <td width="25%" class="text-center">
                          <!-- {{ props.item.duplicate }} -->
                          <v-icon color="green" v-if="props.item.duplicate === 'Y'">mdi-checkbox-marked-circle-outline</v-icon>
                          <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
                          <v-btn
                            class="mx-2 ml-4 white--text"
                            fab
                            x-small
                            :color="color.theme"
                            @click="fn_previewfile(props.item)"
                            :disabled="props.item.duplicate === 'N'"
                            >
                            <v-icon dark>
                                mdi-eye
                            </v-icon>
                            </v-btn>
                          <v-btn
                            class="mx-2 white--text"
                            fab
                            x-small
                            :color="color.theme"
                            @click="fn_download(props.item)"
                            :disabled="props.item.duplicate === 'N'"
                            >
                            <v-icon dark>
                                mdi-arrow-down-bold-circle
                            </v-icon>
                            </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>                
                </div>      
              </v-flex>
            </v-layout>
            <div class="text-center" v-if="loaddataprogress === true">
              <v-progress-circular
                :size="50"
                :color="color.theme"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
        
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="clearData()">
              {{ $t('shopdee.close') }}
            </v-btn>
              <v-btn
                class="white--text"
                :color="color.theme"
                :disabled="filemultipleshopdee.length === 0"
                @click="openDialogImportFileMed = true"
              >
                {{ $t('med.importfilemed') }}
              </v-btn>
       
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <dialogImportFileMed
        :show="openDialogImportFileMed"
        :count="filemultipleshopdee.length || count"
        @closedialog="openDialogImportFileMed = false"
      ></dialogImportFileMed>
      <previewfile
        :arrayfile="dtshopdee"
        :show="openpreviewfile"
        :filedata_1="currentfile"
        @closepreviewfile="openpreviewfile = false"
        @openpreviewfile="openpreviewfile = true"
      ></previewfile>
  
    </v-row>
  </template>
  <script>
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import VueCookies from "vue-cookies";
  import dialogImportFileMed from "../optional/dialog-importfilemed";
  import previewfile from "../optional/dialog-preview.vue";
  import CryptoJS from "crypto-js";

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  export default {
    props: ["show"],
    components: {
      dialogImportFileMed,
      previewfile
    },
    data: function() {
      return {
        currentfile:{},
        openpreviewfile: false,
        rootfile: [],
        dtshopdee: [],
        checkedListHospital: true,
        back: false,
        openDialogImportFileMed: false,
        loaddataprogress: false,
        statusmultipleshopdee: false,
        filemultipleshopdee: [],
        showSelectFile: false,
        showClose: false,
        count: 1,
        headers: [
          {
            text: "shopdee.document_id",
            align: "left",
            value: "document_id",
            width: "25%",
            sortable: false,
          },
          {
            text: "shopdee.document_type",
            align: "center",
            value: "document_type",
            width: "20%",
            sortable: false,
          },
          {
            text: "shopdee.document_date",
            align: "center",
            value: "document_date",
            width: "20%",
            sortable: false,
          },
          {
            text: "shopdee.duplicate_status",
            align: "center",
            value: "duplicate",
            width: "25%",
            sortable: false,
          },
          
        ]
      };
    },
    computed: {
      ...mapState([
        "username",
        "authorize",
        "account_active",
        "color",
        "role_level",
      ]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      shower: {
        get() {
          if (this.show === true) {
            this.getDatataxbox();
          }
          return this.show;
        },
      },
      headerTable() {
        return (
          "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
        );
      },
    },
    methods: {
     async fn_download(item){
        console.log("download",item);
         let url = item.url_file;
         this.axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = item.document_id
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    
    
  
        // console.log("== item",item);
        // console.log("item",item.url_file);
       

        // let auth = await gbfGenerate.generateToken();

        // this.axios
        //   .get(url, {
        //     headers: { Authorization: auth.code },
        //     onDownloadProgress: (progressEvent) => {
        //       let progresspercent = parseInt(
        //         Math.round((progressEvent.loaded / progressEvent.total) * 100)
        //       );
        //     },
        //     withCredentials: false,
        //     responseType: "arraybuffer",
        //   })
        //   .then((response) => {
        //     console.log(response.headers['content-type']);
        //     const blob = new Blob([response.data]);
        //     const content = response.headers['content-type'];
        //     saveAs(blob,item.document_id)
        //   })
        //   .catch((error) => {
        //     Toast.fire({
        //       icon: "error",
        //       // "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
        //       title:error
        //         // this.$t("toast.cannotdownload") +
        //         // item.document_id +
        //         // this.$t("toast.text.textth"),
        //     });
        //     console.log(error);
        //   });
      }, 
      fn_previewfile(file){
        this.currentfile = file;
        this.openpreviewfile = true;
      },
      async getDatataxbox() {
        this.loaddataprogress = true;
        let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
        user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
        let payload = {
          oneid_user_id: user_id
        };
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get/taxbox_data", payload, {
              headers: { Authorization: auth.code } 
            }
          )
          .then((response) => {
            console.log("response", response);
            this.rootfile = [];
            if (response.data.status === "OK") {
              this.loaddataprogress = false;
              this.rootfile = response.data.data;
              this.dtshopdee = [];
                for (var i = 0; i < response.data.data.length; i++) {
                let datashopdee = {};
                datashopdee["document_type"] = response.data.data[i].document_type;
                datashopdee["duplicate"] = response.data.data[i].duplicate;
                datashopdee["file_name"] = response.data.data[i].filename;
                datashopdee["data_type"] = response.data.data[i].data_type;
                datashopdee["file_type"] = response.data.data[i].data_type;
                datashopdee["buyer_serial_no"] = response.data.data[i].buyer_serial_no;
                datashopdee["document_date"] = response.data.data[i].document_date === "" 
                    ? "-" : `${response.data.data[i].document_date.slice(6,8)}/${response.data.data[i].document_date.slice(4,6)}/${response.data.data[i].document_date.slice(0,4)}`;
                datashopdee["document_id"] = response.data.data[i].document_id;
                datashopdee["document_type_code"] = response.data.data[i].document_type_code;
                datashopdee["process_id"] = response.data.data[i].process_id;
                datashopdee["seller_branch_id"] = response.data.data[i].seller_branch_id;
                datashopdee["seller_tax_id"] = response.data.data[i].seller_tax_id;
                datashopdee["source_system"] = response.data.data[i].source_system;
                datashopdee["url_file"] = response.data.data[i].url_file;
                this.dtshopdee.push(datashopdee);
                }
                console.log("this.dtshopdee",this.dtshopdee);
                console.log("this.rootfile", this.rootfile);
            } else {
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            this.loaddataprogress = false;
            console.log('err', error);
            Toast.fire({
              icon: "error",
              title: error.response.data.errorMessage,
            });
          });
          
      },  
      selectAll() {
        this.statusmultipleshopdee = true;
        this.showSelectFile = false;
        // this.filemultipleshopdee = this.dtshopdee.filter((obj) => obj.duplicate === 'N')
        console.log("this.filemultipleshopdee", this.filemultipleshopdee)
      },
      clearMultipleSelect() {
        this.statusmultipleshopdee = false;
        this.showClose = false;
        this.filemultipleshopdee.splice(0, this.filemultipleshopdee.length);
      },
      clearData() {
        this.$emit('closedialog');
        this.checkedListHospital = true;
        this.clearMultipleSelect();
      },
    },
  }
  </script>
  <style>
  .drag-selector {
    padding: 10px;
  }
  .drag-selector__item {
    align-items: center;
    /* padding: -1px; */
    /* height: 40px;
    padding-left: 16px;
    vertical-align: middle;
    display: flex;
    margin: 5px; */
    /* background-color: rebeccapurple; */
  }
  #borderradius {
    border-radius: 15px;
  }
  #borderradius-img {
    border-radius: 15px 15px 0px 0px;
  }
  #borderradius-word {
    border-radius: 0px 0px 15px 15px;
  }
  #borderradius_btn {
    border-radius: 5px;
  }
  </style>
  